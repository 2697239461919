import {
  ActionIcon,
  Button,
  Menu,
  rem,
  Text,
  useMantineColorScheme,
} from "@mantine/core";
import { Link, useFetcher } from "@remix-run/react";
import { getRoute } from "~/routes";
import { constants } from "~/constants";
import {
  IconDotsVertical,
  IconEdit,
  IconCopy,
  IconShare,
  IconTrash,
  IconCheck,
} from "@tabler/icons-react";
import { useEffect } from "react";
import { notifications } from "@mantine/notifications";

export function UserVideoMenu({ videoData, setIsLoading }) {
  const deleteVideoFetcher = useFetcher();
  const handleDeleteVideo = () => {
    setIsLoading(true);
    deleteVideoFetcher.submit(
      {},
      {
        method: "post",
        action: getRoute("deleteVideo", { video_id: videoData.id }),
      }
    );
  };
  useEffect(() => {
    if (deleteVideoFetcher.data && deleteVideoFetcher.data.message) {
      notifications.show({
        title: "Success",
        message: deleteVideoFetcher.data.message,
        color: "teal",
        autoClose: 5000,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
      });
    } else if (deleteVideoFetcher.data && deleteVideoFetcher.data.error) {
      notifications.show({
        title: "Error",
        message: deleteVideoFetcher.data.error,
        color: "red",
        autoClose: false,
        icon: <IconTrash style={{ width: rem(18), height: rem(18) }} />,
      });
    }
    setIsLoading(false);
  }, [deleteVideoFetcher.data]);

  const cloneVideoFetcher = useFetcher();
  const handleCloneVideo = () => {
    setIsLoading(true);
    cloneVideoFetcher.submit(
      {},
      {
        method: "post",
        action: getRoute("cloneVideo", { video_id: videoData.id }),
      }
    );
  };
  useEffect(() => {
    if (cloneVideoFetcher.data && cloneVideoFetcher.data.message) {
      notifications.show({
        title: "Success",
        message: cloneVideoFetcher.data.message,
        color: "teal",
        autoClose: 5000,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
      });
    } else if (cloneVideoFetcher.data && cloneVideoFetcher.data.error) {
      notifications.show({
        title: "Error",
        message: cloneVideoFetcher.data.error,
        color: "red",
        autoClose: false,
        icon: <IconTrash style={{ width: rem(18), height: rem(18) }} />,
      });
    }
    setIsLoading(false);
  }, [cloneVideoFetcher.data]);

  return (
    <Menu shadow="md" withArrow position="bottom-end">
      <Menu.Target>
        <ActionIcon size={"30px"} variant="default">
          <IconDotsVertical size={16} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          component={Link}
          to={getRoute("video", { video_slug: videoData.slug })}
          leftSection={<IconEdit size={14} />}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          component={"button"}
          onClick={handleCloneVideo}
          leftSection={<IconCopy size={14} />}
        >
          Duplicate
        </Menu.Item>
        {/*<Menu.Item leftSection={<IconShare size={14} />}>Publish</Menu.Item>*/}
        <Menu.Divider></Menu.Divider>
        <Menu.Item
          component={"button"}
          onClick={handleDeleteVideo}
          leftSection={<IconTrash size={14} />}
          color="red"
        >
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
