// UserVideo.tsx
import { useState } from "react";
import { ActionIcon, Badge, Box, Group, Text, Tooltip } from "@mantine/core";
import { Link } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import {
  IconCheck,
  IconClock,
  IconDotsVertical,
  IconExclamationCircle,
  IconEye,
} from "@tabler/icons-react";
import { UserVideoMenu } from "~/components/AppContent/Items/UserVideoMenu";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { getRoute } from "~/routes";
import { UserVideoStatusBadge } from "~/components/AppContent/Items/UserVideoStatusBadge";
import { UserVideoPreview } from "~/components/AppContent/Items/UserVideoPreview";
import { UserVideoTitle } from "~/components/AppContent/Items/UserVideoTitle";

dayjs.extend(relativeTime);

export function UserVideo({ videoData }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const relativeTime = dayjs(videoData.updatedAt).fromNow();
  const exactTime = dayjs(videoData.updatedAt).format("MMM D, YYYY (hh:mm a)");
  const createdTime = dayjs(videoData.createdAt).format(
    "MMM D, YYYY (hh:mm a)"
  );
  const tooltipLabel = (
    <>
      <Text size="xs">
        Created: <b> {createdTime}</b>
      </Text>
      <Text size="xs">
        Last edited: <b> {exactTime}</b>
      </Text>
    </>
  );
  const truncatedTitle =
    videoData.title.length > 44
      ? videoData.title.slice(0, 44) + "..."
      : videoData.title;

  return (
    <div
      className={`video-item video-item--${videoData.videoAspectRatio}`}
      style={{ position: "relative" }}
    >
      {/* <div style={{ position: "absolute", right: 10, top: 10 }}>
        {<UserVideoStatusBadge videoData={videoData}></UserVideoStatusBadge>}
      </div> */}
      <Link to={getRoute("video", { video_slug: videoData.slug })}>
        <UserVideoPreview
          isLoading={isLoading}
          videoData={videoData}
        ></UserVideoPreview>
      </Link>
      <Box className={"video-item__info"} pt={"sm"}>
        <Group justify="space-between" align="top">
          <Box style={{ flex: 1, minWidth: 0 }}>
            <UserVideoTitle setIsLoading={setIsLoading} videoData={videoData} />
            <Tooltip position="bottom-start" label={tooltipLabel} multiline>
              <Text span size="xs" c="dimmed">
                {relativeTime}
              </Text>
            </Tooltip>
          </Box>
          <Box>
            <UserVideoMenu videoData={videoData} setIsLoading={setIsLoading} />
          </Box>
        </Group>
      </Box>
    </div>
  );
}
